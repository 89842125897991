import { Route, Routes } from "react-router-dom";
import Qrscan from "../components/QRscan/QrScan";
import ChangePassword from "../pages/ChangePassword";
import CodeRecovery from "../pages/CodeRecovery";
import ForgotPassword from "../pages/ForgotPassword";
import Details from "../pages/FR/Details";
import SignIn from "../pages/FR/Login";
import PersonalInfo from "../pages/FR/personalInfo";
import AdminUsers from "../pages/FRAdmin/AdminUsers";
import CreateAgency from "../pages/FRAdmin/Agency/CreateAgency";
import Invite from "../pages/FRAdmin/Agency/Invite";
import SuccessCreateAgency from "../pages/FRAdmin/Agency/sucess";
import ImportUsers from "../pages/FRAdmin/ImportUsers";
import LoginAdmin from "../pages/FRAdmin/LoginAdmin";
import ManageUsers from "../pages/FRAdmin/ManageUsers";
import ScanHistory from "../pages/FRAdmin/ScanHistory";
import ResetPassword from "../pages/FRAdmin/ResetPassword";
import Settings from "../pages/FRAdmin/Settings";
import PasswordRecoveryEmail from "../pages/PasswordRecovery";
import QRScanCodePage from "../pages/QRScanCodePage";
import SearchSubscriber from "../pages/SearchSubscriber";
import ValidationCode from "../pages/ValidationCode";
import VerificationCodePassword from "../pages/VerificationCodePassword";
import HelpVideosPage from "../pages/Videos";
import OnEnterRoute from "./OnEnterRoute";
import ScanFace from "../pages/ScanFace";
import BiometricAlternativeFaces from "../pages/FR/BiometricAlternativeFaces";
import PendingConfirmationAgency from "../pages/FRAdmin/Agency/pendingConfirmation";
import ConfirmAgencyOwner from "../pages/FRAdmin/Agency/confirmAgencyOwner";

//index routes
const RouterApp: React.FC<any> = () => {
  return (
    <Routes>
      <Route element={<OnEnterRoute />}>
        <Route path="/admin" element={<LoginAdmin />} />
        <Route path="/admin/reset" element={<ResetPassword />} />
        <Route path="/admin/createAgency" element={<CreateAgency />} />
        <Route
          path="/admin/successCreateAgency"
          element={<SuccessCreateAgency />}
        />
        <Route
          path="/admin/pendingAgencyConfirmation"
          element={<PendingConfirmationAgency />}
        />
        <Route path="/admin/manageUsers" element={<ManageUsers />} />
        <Route path="/admin/scanHistory" element={<ScanHistory />} />
        <Route path="/admin/settings" element={<Settings />} />
        <Route path="/admin/importUsers" element={<ImportUsers />} />
        <Route path="/admin/adminUsers" element={<AdminUsers />} />
        <Route path="/admin/invite" element={<Invite />} />
        <Route path="/admin/confirmAgency" element={<ConfirmAgencyOwner />} />
        <Route path="/" element={<SignIn />} />
        <Route path="*" element={<SignIn />} />
        <Route path="CodeRecovery" element={<CodeRecovery />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ChangePassword" element={<ChangePassword />} />
        <Route path="PasswordRecovery" element={<PasswordRecoveryEmail />} />
        <Route path="ValidationCode" element={<ValidationCode />} />
        <Route path="SearchSubscriber" element={<SearchSubscriber />} />
        {/* <Route path="/helpVideos" element={<HelpVideosPage />} /> */}
        <Route
          path="VerificationPassword"
          element={<VerificationCodePassword />}
        />
        <Route path="subscriberInfo" element={<PersonalInfo />} />
        <Route path="subscriberInfo/details" element={<Details />} />
        <Route
          path="alternativeFaces"
          element={<BiometricAlternativeFaces />}
        />
        <Route path="Qrscan" element={<Qrscan />} />
        <Route path="QRScanCode" element={<QRScanCodePage />} />
        <Route path="ScanFace" element={<ScanFace />} />
      </Route>
    </Routes>
  );
};

export default RouterApp;

import { Allergy } from "./../Interfaces/allergy";
import { Disability } from "./../Interfaces/disability";
import { MedicalCondition } from "./../Interfaces/medicalcondition";
import { Medication } from "./../Interfaces/medication";
import { Membership } from "./../Interfaces/membership";
import { Pet } from "./../Interfaces/pet";
import { Supplement } from "./../Interfaces/supplement";
import { Surgery } from "./../Interfaces/surgery";
import { Vaccine } from "./../Interfaces/vaccine";
import {
  ALLERGIES_API,
  DISABILITIES_API,
  MEDICAL_CONDITION_API,
  MEDICATIONS_API,
  MEMBERSHIP_API,
  PETS_API,
  SUPPLEMENTS_API,
  SURGERIES_API,
  VACCINES_API,
} from "./CONSTANTS";
import customAxios from "./customAxios";

export const getAllergies: (userId: string) => Promise<Allergy> = (userId) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Allergy[]>(`${ALLERGIES_API}${userId}`)
        .then((res: any) => {
          console.log(`${getAllergies.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getAllergies.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getAllergies.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getDisabilitys: (userId: string) => Promise<Disability> = (
  userId
) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Disability[]>(`${DISABILITIES_API}${userId}`)
        .then((res: any) => {
          console.log(`${getDisabilitys.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getDisabilitys.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getDisabilitys.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getMedicalConditions: (
  userId: string
) => Promise<MedicalCondition> = (userId) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<MedicalCondition[]>(`${MEDICAL_CONDITION_API}${userId}`)
        .then((res: any) => {
          console.log(`${getMedicalConditions.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getMedicalConditions.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getMedicalConditions.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getMedications: (userId: string) => Promise<Medication> = (
  userId
) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Medication[]>(`${MEDICATIONS_API}${userId}`)
        .then((res: any) => {
          console.log(`${getMedications.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getMedications.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getMedications.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getPets: (userId: string) => Promise<Pet> = (userId) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Pet[]>(`${PETS_API}${userId}`)
        .then((res: any) => {
          console.log(`${getPets.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getPets.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getPets.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getSupplements: (userId: string) => Promise<Supplement> = (
  userId
) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Supplement[]>(`${SUPPLEMENTS_API}${userId}`)
        .then((res: any) => {
          console.log(`${getSupplements.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getSupplements.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getSupplements.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getSurgerys: (userId: string) => Promise<Surgery> = (userId) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Surgery[]>(`${SURGERIES_API}${userId}?sort=year,DESC`)
        .then((res: any) => {
          console.log(`${getSurgerys.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getSurgerys.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getSurgerys.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getVaccines: (userId: string) => Promise<Vaccine> = (userId) => {
  // console.log(`userServices > ${confirmSignUp.name} called...`)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Vaccine[]>(`${VACCINES_API}${userId}`)
        .then((res: any) => {
          console.log(`${getVaccines.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(`${getVaccines.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getVaccines.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getMembership: (userId: string) => Promise<Membership> = (
  userId
) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Membership>(`${MEMBERSHIP_API}${userId}`)
        .then((res: any) => {
          console.log(`${getMembership.name} > axios res=`, res);
          resolve(res.data.data[0]);
        })
        .catch((err) => {
          console.error(`${getMembership.name} > axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${getMembership.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useState } from "react";
import {
  STATUSES,
  StatusesType,
} from "../../../components/ImportUsers/PreviewResults/PreviewResults";
import { FirstResponderData } from "../../../Interfaces/FirstResponder";

const columns: GridColDef[] = [
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return <div style={{ color: colorLabel }}>{textLabel}</div>;
    },
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return <div style={{ color: colorLabel }}>{textLabel}</div>;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    editable: true,
    renderCell: (params) => {
      //eslint-disable-next-line
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      let resultMail = expression.test(params.value);
      let colorLabel =
        Object.keys(params.value).length > 0 && resultMail ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return <div style={{ color: colorLabel }}>{textLabel}</div>;
    },
  },
  {
    field: "badge",
    headerName: "Badge/ Employee ID #",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return (
        <div
          style={{
            color: colorLabel,
          }}
        >
          {textLabel}
        </div>
      );
    },
  },
  {
    field: "address",
    headerName: "Agency Address",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return (
        <div
          style={{
            color: colorLabel,
          }}
        >
          {textLabel}
        </div>
      );
    },
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return (
        <div
          style={{
            color: colorLabel,
          }}
        >
          {textLabel}
        </div>
      );
    },
  },
  {
    field: "state",
    headerName: "State",
    editable: true,
    flex: 1,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ? "#000" : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return (
        <div
          style={{
            color: colorLabel,
          }}
        >
          {textLabel}
        </div>
      );
    },
  },
  {
    field: "zipCode",
    headerName: "ZIP Code",
    flex: 1,
    editable: true,
    renderCell: (params) => {
      let colorLabel =
        Object.keys(params.value).length > 0 ||
        !new RegExp(/^[0-9]*$/).test(params.value)
          ? "#000"
          : "#FC6868";
      let textLabel =
        Object.keys(params.value).length > 0 ? params.value : "[Empty]";
      return (
        <div
          style={{
            color: colorLabel,
          }}
        >
          {textLabel}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 2,
    renderCell: (params) => {
      return (
        <div
          style={{
            color: STATUSES[params.value as StatusesType].color,
            backgroundColor:
              STATUSES[params.value as StatusesType].color + "1F",
            paddingRight: "8px",
            paddingLeft: "8px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderRadius: "6px",
          }}
        >
          {STATUSES[params.value as StatusesType].label.toUpperCase()}
        </div>
      );
    },
  },
];

interface Props {
  users?: any[];
  isloading: boolean;
  setFirstResponders: (firstResponders: FirstResponderData[]) => void;
}

const ImportUsersTable: FC<Props> = ({
  users,
  isloading,
  setFirstResponders,
}) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      sx={{
        color: "black",
        border: 0,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-row": {
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        },
        ".MuiDataGrid-columnHeaders": {
          borderRadius: "4px",
          backgroundColor: "#E0E2E9",
        },
        "& .MuiCheckbox-root": {
          color: "#000094",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: 400,
        },
      }}
      loading={isloading}
      rows={users ?? []}
      getRowId={(row) => row.idx}
      columns={columns}
      editMode="cell"
      isCellEditable={(params) => {
        return false;
        // console.log(params)
        // return params.formattedValue === ""
      }}
      disableColumnMenu
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50]}
      autoHeight
      pagination
      getRowSpacing={(_) => {
        return {
          top: 6,
        };
      }}
      disableSelectionOnClick
    />
  );
};

export default ImportUsersTable;

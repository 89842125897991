import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import useAuthUserData from "../../Hooks/useAuthUserData";
import {
  useDefaultActiveSessionLengthForAgency,
  useFirstRespondersForAgency,
} from "../../Hooks/useFirstResponderService";
import { FirstResponderData } from "../../Interfaces/FirstResponder";
import { sendFirstResponderInvitation } from "../../Services/emailService";
import {
  registerFirstResponder,
  updateFirstResponderbyId,
} from "../../Services/firstResponderService";
import EditClassField from "../EditClassField";

interface Props {
  user?: FirstResponderData;
  open: boolean;
  handleClose: () => void;
}

const EditUserDialog: FC<Props> = ({ user, open, handleClose }) => {
  const { user: authUser } = useAuthUserData();
  const { defaultActiveSessionLength } =
    useDefaultActiveSessionLengthForAgency();
  const { firstResponders, loading } = useFirstRespondersForAgency();
  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.lastName ?? "");
  const [badge, setBadge] = useState(user?.badge ?? "");
  const [address, setAddress] = useState(user?.address ?? "");
  const [city, setCity] = useState(user?.city ?? "");
  const [state, setState] = useState(user?.state ?? "");
  const [zipCode, setZipCode] = useState(user?.zipCode ?? "");
  const [email, setEmail] = useState(user?.email ?? "");
  const [activeSessionLength, setActiveSessionLength] = useState(
    user?.activeSessionLength ?? defaultActiveSessionLength ?? 60
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<"invite" | true | null>(null);

  useEffect(() => {
    if (!user && defaultActiveSessionLength) {
      setActiveSessionLength(defaultActiveSessionLength);
    }
  }, [user, defaultActiveSessionLength]);

  const handleSubmit = async (invite: boolean) => {
    if (
      !firstName ||
      !lastName ||
      !badge ||
      !city ||
      !state ||
      !email ||
      !zipCode ||
      !address
    ) {
      setError(true);
      setErrorMessage("Missing Required Fields");
      return;
    }

    if (loading) {
      setError(true);
      setErrorMessage("Loading.. Please try again");
      return;
    }

    const password =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    setIsLoading(true);
    //Edit action
    if (user) {
      if (user && !new RegExp(/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/).test(email)) {
        setError(true);
        setErrorMessage("Email format is invalid!");

        setIsLoading(false);

        return;
      }

      const emailChanged = email !== user.email;

      try {
        await updateFirstResponderbyId(
          user.id,
          {
            firstName,
            lastName,
            badge,
            city,
            state,
            zipCode,
            address,
            email,
            activeSessionLength,
            status: invite ? "invited" : user.status,
          },
          emailChanged ? user.email : null
        );

        setIsLoading(false);
        setSuccess(invite ? "invite" : true);

        if (emailChanged) {
          // Updates OTP
          await updateFirstResponderbyId(
            user.id,
            {
              otp: password,
              subCognitoId: null,
              status:
                user.status === "new" || user.status === "invited"
                  ? user.status
                  : "unverified",
            },
            null
          );

          await sendFirstResponderInvitation({
            idCode: user.mmhId,
            password: password,
            adminUsername: authUser.username,
            email,
          })
            .then((res: any) => {
              console.log(
                "🍉 ~ file: EditUserDialog.tsx:99 ~ .then ~ res:",
                res
              );
            })
            .catch((err: any) => {
              console.log(
                "🍉 ~ file: EditUserDialog.tsx:103 ~ .then ~ err:",
                err
              );
            });
        }
        cleanForm();
      } catch (err: any) {
        setIsLoading(false);
        setError(true);
        setErrorMessage(err);
      }
    } else {
      //Register action
      console.log("🍉 registerFirstResponder");
      registerFirstResponder({
        firstName,
        lastName,
        badge,
        city,
        state,
        email,
        zipCode,
        address,
        activeSessionLength,
        rol: "firstResponder",
        status: invite ? "invited" : "new",
        username: email,
        mfa: false,
        agency: authUser.agency,
        agencyName: authUser.username,
      })
        .then((res: any) => {
          setIsLoading(false);
          setSuccess(invite ? "invite" : true);
          cleanForm();
        })
        .catch((err: any) => {
          console.log("🍉 ~ file: EditUserDialog ~ handleSubmit ~ err:", err);
          setIsLoading(false);
          setError(true);
          setErrorMessage(
            "Email format invalid or already used by another user"
          );
        });
    }
  };

  const cleanForm = () => {
    if (user) return;
    setFirstName("");
    setLastName("");
    setBadge("");
    setCity("");
    setState("");
    setAddress("");
    setZipCode("");
    setEmail("");
    setActiveSessionLength(defaultActiveSessionLength ?? 60);
    setError(false);
    setErrorMessage("");
    setIsLoading(false);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleZipChange = (value: string) => {
    if (value.length > 0) {
      setZipCode(new RegExp(/\d+/g).test(value) ? value : zipCode);
    } else {
      setZipCode("");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setSuccess(null);
        cleanForm();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose();
              setSuccess(null);
              cleanForm();
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {success ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#86E0A7">
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              {user ? (
                <Typography color="#242627" fontSize={25} fontWeight={700}>
                  User updated {success === "invite" ? "and re-invited" : ""}{" "}
                  succesfully
                </Typography>
              ) : (
                <Typography color="#242627" fontSize={25} fontWeight={700}>
                  User added {success === "invite" ? "and invited" : `as "New"`}{" "}
                  succesfully
                </Typography>
              )}
            </Grid>
            <Grid item mt={0.5}>
              {user ? (
                <Typography color="#242627" fontSize={16} fontWeight={400}>
                  {success === "invite"
                    ? "Invitation email with login credentials has been sent to the user"
                    : "No further action required"}
                </Typography>
              ) : (
                <Typography color="#242627" fontSize={16} fontWeight={400}>
                  {success === "invite"
                    ? "Invitation email with login credentials has been sent to the user"
                    : "You will need to invite the user manually"}
                </Typography>
              )}
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  border: "1px solid rgba(0, 0, 148, 0.4)",
                }}
                onClick={() => {
                  handleClose();
                  setSuccess(null);
                  window.location.reload();
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="left"
            px={8}
            pb={8}
            rowSpacing={3}
          >
            <Grid item>
              <Typography fontSize={25} fontWeight={700}>
                {user ? "Edit" : "Add New"} User
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  First Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Last Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  MMH ID
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#F0F0F0",
                    color: "black",
                    padding: "16px 16px 16px 18px",
                    borderRadius: "8px",
                  }}
                >
                  {user ? user.mmhId : "Auto-generated"}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Badge/Employee ID #
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={badge}
                  onChange={(e) => setBadge(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Agency Address
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  City
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={3} alignItems="stretch">
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  State
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Zip Code
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={zipCode}
                  onChange={(e) => handleZipChange(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Email
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <EditClassField
                  value={activeSessionLength}
                  setValue={setActiveSessionLength}
                  label="First Responder's Class"
                />
              </Grid>
            </Grid>
            {user ? (
              <Grid item display="inline">
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#000094",
                    textTransform: "none",
                    padding: "8px 20px",
                    mr: 2,
                  }}
                  onClick={() => handleSubmit(false)}
                >
                  Update
                </LoadingButton>
                {user.status !== "active" && (
                  <LoadingButton
                    loading={isLoading}
                    variant="outlined"
                    sx={{
                      color: "#000094",
                      textTransform: "none",
                      border: "1px solid rgba(0, 0, 148, 0.4)",
                      padding: "8px 20px",
                    }}
                    onClick={() => handleSubmit(true)}
                  >
                    Update and Re-Invite
                  </LoadingButton>
                )}
              </Grid>
            ) : (
              <Grid item display="inline">
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#000094",
                    textTransform: "none",
                    padding: "8px 20px",
                    mr: 2,
                  }}
                  onClick={() => handleSubmit(true)}
                >
                  Add and Send Invite
                </LoadingButton>
                {/* <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  sx={{
                    color: "#000094",
                    textTransform: "none",
                    border: "1px solid rgba(0, 0, 148, 0.4)",
                    padding: "8px 20px",
                  }}
                  onClick={() => handleSubmit(false)}
                >
                  Add as New
                </LoadingButton> */}
              </Grid>
            )}
            {error && (
              <Grid item>
                <Typography color="red" fontSize={13} fontWeight={600}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default EditUserDialog;
